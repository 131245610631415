import './index.scss';

import React from 'react';
import PropTypes from 'prop-types';
import Flash from '../../../assets/images/flash.svg';
import { SessionStorage } from '../../burger-king-lib/utils/storage.web';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';

const ToggleBetweenValues = props => {
  const {
    leftContent,
    rightContent,
    id,
    checked,
    onChange,
    name,
    disabled,
    theme,
    value
  } = props;
  const isExpressDelivery = SessionStorage.get(
    LocalSessionKey.RESTAURANT_DETAIL
  )?.is_express_delivery;
  return (
    <div className={`toggle ${theme}`}>
      {leftContent && (
        <>
          <div
            role="presentation"
            onClick={!checked ? null : () => onChange('delivery')}
            className={`u_cursor_pointer ${
              disabled ? 'u_cursor_not_allowed' : ''
            } toggle__right ${!checked ? 'toggle__right_active' : ''}`}
          >
            {leftContent}
          </div>
          {isExpressDelivery && (
            <img
              src={Flash}
              alt="Express Delivery"
              className="express-delivery-icon"
            />
          )}
        </>
      )}
      <label className="toggle__label">
        <input
          value={value}
          type="checkbox"
          className="toggle__checkbox"
          disabled={disabled}
          name={name}
          id={id}
          checked={checked}
          onChange={e =>
            onChange(value !== 'takeaway' ? 'takeaway' : 'delivery')
          }
        />
        <span
          className={`toggle__custom ${
            !checked ? '' : 'toggle__custom_uncheck'
          } ${disabled ? 'toggle__custom_disabled' : ''}`}
        />
      </label>
      {rightContent && (
        <div
          role="presentation"
          onClick={checked ? null : () => onChange('takeaway')}
          className={`u_cursor_pointer ${
            disabled ? 'u_cursor_not_allowed' : ''
          } toggle__left ${checked ? 'toggle__left_active' : ''}`}
        >
          {rightContent}
        </div>
      )}
    </div>
  );
};

ToggleBetweenValues.propTypes = {
  leftContent: PropTypes.string,
  rightContent: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  theme: PropTypes.string
};

ToggleBetweenValues.defaultProps = {
  leftContent: '',
  rightContent: '',
  name: '',
  id: '',
  onChange: () => {},
  disabled: false,
  checked: false,
  theme: ''
};

export default ToggleBetweenValues;
