import React, { useState } from 'react';
import moment from 'moment';
import TakeAwayIcon from '../../../assets/images/take-away-black.svg';
import DineInIcon from '../../../assets/images/dine-in-black.svg';
import DeliveryIcon from '../../../assets/images/delivery-black.svg';
import ParkingIcon from '../../../assets/images/parking-black.svg';
import {
  getCallIcon,
  getDirectionImage,
  getRestaurantFinderImage,
  getDeliveryIcon,
  getParkingIcon,
  getTakeawayIcon
} from '../../utils/imageChanges';
import './index.scss';

const getTime = minutes => {
  let hours;
  let restMinutes;
  const onedayMinutes = 1440; // 24*60

  if (minutes < 60) {
    return `${minutes} mins`;
  }
  if (minutes > 60 && minutes < onedayMinutes) {
    hours = Math.floor(minutes / 60);
    restMinutes = minutes % 60;
    return `${hours} hrs ${restMinutes} mins`;
  }
  const days = Math.floor(minutes / 60 / 24);
  restMinutes = minutes % onedayMinutes;
  hours = Math.floor(restMinutes / 60);
  restMinutes %= 60;
  return `${days} days ${hours} hrs ${restMinutes} mins`;
};

const RestaurantCard = props => {
  const {
    data,
    onClick,
    theme,
    isRestaurantFinder,
    onCallClick,
    outletList,
    index,
    getNavigation
  } = props;

  const {
    outlet_name,
    address,
    takeaway,
    dine_in,
    parking,
    is_delivery,
    distance,
    time_to_travel,
    phone_no,
    lat,
    long,
    outlet_address,
    isActive,
    closes_at,
    opens_at,
    message
  } = data;

  const opens = moment(opens_at, 'HH:mm:ss').format('h.mm A');
  const closes = moment(closes_at, 'HH:mm:ss').format('h.mm A');

  // State for "Show More/Less"
  const [isExpanded, setIsExpanded] = useState(false);

  // Toggle address expansion
  const toggleAddress = () => setIsExpanded(!isExpanded);

  return (
    <div
      className={`restaurant-card u_cursor_pointer ${
        isExpanded ? 'restaurant-card--expanded' : ''
      }`}
      role="presentation"
      onClick={() => onClick(data)}
      style={{
        marginBottom: outletList?.length === index + 1 ? '7rem' : ''
      }}
    >
      <div className="restaurant-card__content">
        <div className="restaurant-card__details_wrapper">
          <div className="restaurant-card__details">
            <div>
              <p className="restaurant-card__name">{outlet_name}</p>
              <p className="restaurant-card__timings">
                Store Timing ({opens}-{closes})
              </p>

              {/* Address with Show More/Less */}
              <p className="restaurant-card__description">
                {address.length > 50 ? (
                  <>
                    {isExpanded ? address : `${address.slice(0, 40)}...`}
                    <span
                      className="restaurant-card__toggle"
                      onClick={e => {
                        e.stopPropagation(); // Prevent card click
                        toggleAddress();
                      }}
                    >
                      {isExpanded ? ' Show Less' : ' Show More'}
                    </span>
                  </>
                ) : (
                  address
                )}
              </p>

              <p
                className={
                  message === 'Open Now'
                    ? 'restaurant-card__time'
                    : 'restaurant-card__closed'
                }
              >
                {message}
              </p>
              <p className="restaurant-card__range">
                {distance} km away · {getTime(time_to_travel)}
              </p>
            </div>
            {isRestaurantFinder && (
              <div>
                <div className="restaurant-card__contact">
                  <img
                    src={getCallIcon(theme)}
                    alt="call"
                    className="restaurant-card__contact-img"
                    onClick={e => {
                      e.stopPropagation(); // Prevent card click
                      onCallClick(phone_no);
                    }}
                    role="presentation"
                  />
                </div>
                <div className="restaurant-card__contact">
                  <img
                    src={getDirectionImage(theme)}
                    alt="direction"
                    className="restaurant-card__contact-img"
                    onClick={e => {
                      e.stopPropagation(); // Prevent card click
                      getNavigation(address, lat, long);
                    }}
                    role="presentation"
                  />
                </div>
              </div>
            )}
          </div>
          {isRestaurantFinder || <div className="restaurant-card__divider" />}
          <div
            className={`restaurant-card__options ${
              takeaway || dine_in || parking || is_delivery ? 'border' : ''
            }`}
          >
            {takeaway && (
              <RestaurantOption
                label="Takeaway"
                isActive={message === 'Open Now'}
                icon={getTakeawayIcon(theme)}
                fallbackIcon={TakeAwayIcon}
                theme={theme}
              />
            )}
            {dine_in && (
              <RestaurantOption
                label="Dine-in"
                isActive={message === 'Open Now'}
                icon={getRestaurantFinderImage(theme)}
                fallbackIcon={DineInIcon}
                theme={theme}
              />
            )}
            {parking && (
              <RestaurantOption
                label="Parking"
                isActive={message === 'Open Now'}
                icon={getParkingIcon(theme)}
                fallbackIcon={ParkingIcon}
                theme={theme}
              />
            )}
            {is_delivery && (
              <RestaurantOption
                label="Delivery"
                isActive={message === 'Open Now'}
                icon={getDeliveryIcon(theme)}
                fallbackIcon={DeliveryIcon}
                theme={theme}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const RestaurantOption = ({ label, isActive, icon, fallbackIcon, theme }) => {
  const textColor = ['Delivery', 'Dine-in', 'Parking', 'Takeaway'].includes(
    label
  )
    ? theme === 'delivery'
      ? '#EE7000' // Orange for delivery theme
      : '#6F3C2F' // Brown for other themes
    : '';

  return (
    <div className={`restaurant-card__list ${theme}`}>
      <img
        src={isActive ? icon : fallbackIcon}
        alt={label.toLowerCase()}
        className="restaurant-card__img"
      />
      <span
        className={`restaurant-card__list-name ${label.toLowerCase()} ${
          isActive ? '' : 'disabled'
        }`}
        style={{ color: textColor }} // Apply inline color styling
      >
        {label}
      </span>
    </div>
  );
};

export default RestaurantCard;
