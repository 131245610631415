import '../privacy-policy/index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';

import AccountSubSecTitle from '../../components/accout-subsec-title';
import Footer from '../../containers/footer';

class PromotionalPrivacyPolicy extends Component {
  goBackOnClick = () => {
    const { history } = this.props;
    history.go(-1);
  };

  render() {
    const { goBackOnClick } = this;
    const { theme } = this.props;
    const { location } = window;
    const { pathname } = location;

    const terms_conditions = [
      {
        id: 1,
        content:
          'Every order placed in dine-in/ take away/ delivery is subject to taxes and charges as applicable.'
      },
      {
        id: 2,
        content:
          'Please collect your invoice while placing the order at the restaurant. You can opt to receive electronic copy (e-copy) of your invoice by providing your mobile number while placing the Order or ask for physical copy. The invoice has complete details of the taxes and charges levied.'
      },
      {
        id: 3,
        content:
          'Burger King products has No Synthetic Colours and Artificial Flavours (excluding pepsi and other aerated drinks sold in the restaurant) and the products are for immediate consumption only.'
      },
      // {
      //   id: 4,
      //   content: 'PEPSI PRODUCTS DISCLAIMERS:',
      //   subItems: [
      //     'PEPSI, PEPSI BLACK AND MOUNTAIN DEW CONTAINS CAFFEINE.',
      //     'PLEASE NOTE THAT PEPSI BLACK CONTAIN NON-CALORIC SWEETNERS, THE CARBONATED WATER CONTAINS AN ADMIXTURE OF SUCRALOSE AND ACESULFAME POTASSIUM AND THE SAME IS NOT RECOMMENDED FOR CHILDREN; PREGNANT AND LACTATING MOTHERS.'
      //   ]
      // },
      {
        id: 4,
        content: 'COKE PRODUCTS DISCLAIMERS:',
        subItems: [
          'COCA-COLA, THUMS UP, COCA-COLA ZERO CONTAINS CAFFEINE.',
          'COCA-COLA ZERO CONTAIN NON-CALORIC SWEETENER. THIS CARBONATED WATER CONTAINS AN ADMIXTURE OF SUCRALOSE ANDACESULFAME POTASSIUM. NOT RECOMMENDED FOR CHILDREN; PREGNANT AND LACTATING MOTHERS.'
        ]
      },
      {
        id: 5,
        content:
          'In the New BK Chicken Burger the chicken content is approximate and indicative and is subject to variation in serve sizes, preparation process, sources of supply including seasonal changes/geographical/ weather conditions may have an impact.'
      },
      {
        id: 6,
        content:
          'For product nutrition and allergen information please refer to the menu available in the restaurant, Burger King App/ website.'
      },
      {
        id: 7,
        content:
          'For all product information please refer to the menu available in the restaurants or on the Burger King App/ website. All our products/ingredients are subject to availability.'
      },
      {
        id: 8,
        content:
          'Restaurant timings may vary for different locations. Restaurant operation is subject to State and Local Government guidelines/ orders with respect to permitted services/timings in different zones across cities.'
      },
      {
        id: 9,
        content:
          'Images used on the restaurant display/BK website & App/advertisement are for illustration purpose only and do not represent actual product.'
      },
      {
        id: 10,
        content:
          'For any contest run by Burger King India the entries will only be valid if all the contest T & C and steps are followed accurately.'
      },
      {
        id: 11,
        content:
          'All the contests are valid for the limited period only. Please check the contest T & C for more information. All our voucher contains a unique code.'
      },
      {
        id: 12,
        content:
          'It is mandatory to convey the offer before placing the order for its redemption.'
      },
      {
        id: 13,
        content:
          'By participating in the contest/asking for e-copy of invoice, participants agree to submit his/ her information to Burger King India, giving consent where necessary and appropriate for disclosures.'
      },
      {
        id: 14,
        content:
          'Burger King India has the right to modify/withdraw the offer/ promotional contest at its sole discretion at any time during the contest without any notification.'
      },
      {
        id: 15,
        content:
          'Each participant agrees to be bound by these T&C (including T & C for the contest) and by the sole decision(s) of Burger King India, whose decision shall be final in selecting the winning entries, in relation to all matters. Burger King India will entertain no further correspondence.'
      },
      {
        id: 16,
        content: `TM and ©${new Date().getFullYear()} are owned by Burger King Company LLC. Used under the license. All rights reserved.`
      },
      {
        id: 17,
        content:
          'All the combo offers will be sold as per the Burger King India discretion. The combo offer cannot be sold separately. No two offers can be clubbed together for availing the any additional discount/ benefits.'
      },
      {
        id: 18,
        content:
          'For any further information, you can contact by writing to us at customercare@burgerking.in'
      },
      {
        id: 19,
        content: `TM and ©${new Date().getFullYear()} are owned by Burger King Company LLC. Used under the license. All rights reserved.`
      },
      {
        id: 20,
        content:
          'In case of any dispute, the courts in Mumbai will have exclusive jurisdiction.'
      }
    ];

    const vegTermsData = [
      {
        id: 1,
        content:
          'Tasty Meals starting at just Rs.99* is applicable only on purchase of Crispy Veg regular meal from Burger King restaurants.'
      },
      {
        id: 2,
        content:
          'Prices of other regular meals under the Tasty Meals offer section are as follows:',
        subItems: [
          'Veg Makhani Burst Regular Meal: Rs. 109*',
          'Crunchy Veg Taco Regular Meal: Rs. 119*',
          'Crispy Chicken Regular Meal: Rs.149*',
          'Chicken Makhani Burst Regular Meal: Rs. 159*',
          'Crunchy Chicken Taco Regular Meal: Rs. 169*'
        ]
      },
      {
        id: 3,
        content:
          'Tasty Meals offer is available in selected restaurants in select cities only.'
      },
      {
        id: 4,
        content:
          'Tasty Meals offer is not valid in Airport & Franchised Burger King restaurants.'
      },
      {
        id: 5,
        content:
          'Tasty Meals pricing is excluding any taxes, delivery charges, restaurant handling charges or any other charges/ expenses incurred by us (*).'
      },
      {
        id: 6,
        content:
          'Tasty Meals starting at just Rs.99* is applicable only on regular meals.'
      },
      {
        id: 7,
        content:
          'Regular Meal includes Regular Fries & Regular Pepsi Black only.'
      },
      {
        id: 8,
        content:
          'Regular Meals cannot be upgraded to Medium and/or King (Large) Meals.'
      },
      {
        id: 9,
        content: 'Tasty Meals offer does not apply to other combos.'
      },
      {
        id: 10,
        content:
          'Tasty Meals offer is applicable only in Burger King Dine-in/ Takeaway.'
      },
      {
        id: 11,
        content:
          'Tasty Meals offer cannot be combined with any other offer/coupons.'
      },
      {
        id: 12,
        content: 'Burger/Meal Customisation will be charged extra.'
      }
    ];

    const whoperCampaign = [
      {
        id: 1,
        content:
          'Whopper campaign offer is only available in Burger King Restaurants Only for dine-In/take away Orders.'
      },
      {
        id: 2,
        content:
          'Prices of the Whopper under the Whopper campaign are as follows:',
        subItems: ['Veg Whopper: Rs. 129*', 'Chicken Whopper: Rs. 149*']
      },
      {
        id: 3,
        content:
          '(*) Whopper campaign offer pricing is excluding any taxes, delivery charges, restaurant handling charges or any other charges/ expenses incurred by us.'
      },
      {
        id: 4,
        content:
          'Whopper has 7 layers between the buns i.e. Veg/Chicken Patty, 2 Sauces, Lettuce, Onions, Tomatoes and Gherkins as compared to other Burgers available in Burger King Restaurant.'
      },
      {
        id: 5,
        content:
          'The Product/ ingredients in the product is subject to quality standards, seasonal conditions and supply of products/ ingredients to Burger King Restaurants.'
      },
      {
        id: 6,
        content:
          'Please note that the food and packaging images depicted are for illustrative purposes only and may differ from what you actually receive when ordering from a restaurant.'
      },
      {
        id: 7,
        content: 'Whopper campaign offers premium buns (Glazed buns).'
      },
      {
        id: 8,
        content:
          'Whopper campaign offer cannot be combined with any other offer/ coupon'
      },
      {
        id: 9,
        content:
          'Whopper campaign offer is valid for the limited period only till stocks last.'
      },
      {
        id: 10,
        content:
          'Whopper customization (including additional Cheese slice) will be charged extra.'
      },
      {
        id: 11,
        content: 'Whopper campaign offers does not apply to other combos.'
      },
      {
        id: 12,
        content:
          'Images in this advertisement of Whopper campaign are illustrative and do not represent the actual product(s).'
      },
      {
        id: 13,
        content:
          'Whopper campaign offers is available in selected restaurants in selected cities only.'
      },
      {
        id: 14,
        content:
          'Whopper campaign offers is not valid in Airport & Franchised Burger King restaurants.'
      },
      {
        id: 15,
        content:
          'Burger King India has the right to modify/withdraw the Whopper campaign offers at its sole discretion at any time without any notification.'
      },
      {
        id: 16,
        content:
          'For any further information, you can contact by writing to us at customercare@rbrandsasia.com'
      },
      {
        id: 17,
        content: `TM and ©${new Date().getFullYear()} are owned by Burger King Company LLC. Used under the license. All rights reserved.`
      },
      {
        id: 18,
        content:
          'In case of any dispute, the courts in Mumbai will have exclusive jurisdiction.'
      }
    ];

    const bkExpress = [
      {
        id: 1,
        content:
          'Burger King Express – 15 Minute Deliveries is available only for delivery of Burger King products available only on the orders placed via BK App at selected Burger King restaurants.'
      },
      {
        id: 2,
        content:
          'Burger King reserves the right to launch various offers under delivery from Burger King Express i.e. including not limited to no delivery fee, no other charges, delivery timings, product offering etc. available on products offered on BK App.'
      },
      {
        id: 3,
        content:
          'Burger King reserves the right to cancel, reduce, amend, extend, modify, or withdraw the offer at its sole discretion at any time without prior notice. No claims or questions will be entertained in this regard.'
      },
      {
        id: 4,
        content:
          'Estimated time of arrival (ETA) for every order is 15 minutes however the same is subject to factors such as availability of delivery riders/ third-party delivery service availability, demand, traffic, weather conditions, force majeure events, and other unforeseen circumstances.'
      },
      {
        id: 5,
        content:
          'Orders placed may be split into multiple sub-orders, each potentially having a different ETA.'
      },
      {
        id: 6,
        content:
          'Burger King reserves the right to levy certain charges, such as fees for rain, peak hours, high demand or surge periods, late-night delivery and handling, convenience or platform usage, small cart fees, or other applicable charges, may apply time to time.'
      },
      {
        id: 7,
        content:
          'Burger King reserves the right to refuse or to accept or to cancel any order from any customer beyond the delivery area or otherwise.'
      },
      {
        id: 8,
        content:
          'All charges/ fees will be displayed on the checkout page on placing the order.'
      },
      {
        id: 9,
        content:
          'Burger King is not liable for non-delivery or delays in delivery arising from an incorrect or incomplete address.'
      },
      {
        id: 10,
        content:
          'All the Burger King products are for immediate consumption only.'
      },
      {
        id: 11,
        content:
          'Restaurant operations are subject to state and local government guidelines, including permitted services and timings in different zones.'
      },
      {
        id: 12,
        content:
          'Burger King’s maximum liability is not more than the amount of the order placed by the customer.'
      },
      {
        id: 13,
        content:
          'All the terms and conditions mentioned in the promotional T&C also applies to Burger King Express – 15 Minute Deliveries.'
      },
      {
        id: 14,
        content:
          'For any further information, you can contact by writing to us at customercare@burgerking.in'
      },
      {
        id: 15,
        content:
          'TM and ©2025 are owned by Burger King Company LLC. Used under the license. All rights reserved.'
      },
      {
        id: 16,
        content:
          'In case of any dispute, the courts in Mumbai will have exclusive jurisdiction.'
      }
    ];

    const ccTerms = [
      {
        id: 1,
        content:
          '2 Crispy Veg for ₹79* and 2  Crispy Chicken for ₹99* (this “Offer”) is applicable only on purchase of Crispy Veg and Crispy Chicken from Burger King restaurants.'
      },
      {
        id: 2,
        content:
          'This Offer is available in selected restaurants in select cities only.'
      },
      {
        id: 3,
        content:
          'This Offer is not valid in Airport & Franchised Burger King restaurants.'
      },
      {
        id: 4,
        content:
          'Offer pricings are excluding any taxes, delivery charges, restaurant handling charges or any other charges/ expenses incurred by us (*).'
      },
      {
        id: 5,
        content: 'This Offer does not apply to other combos.'
      },
      {
        id: 6,
        content:
          'This Offer is applicable only in Burger King Dine-in/ Takeaway.'
      },
      {
        id: 7,
        content: 'This Offer cannot be combined with any other offer/coupons.'
      },
      {
        id: 8,
        content: 'Burger/Meal Customization will be charged extra.'
      },
      {
        id: 9,
        content:
          '“Just Add Coke” isn’t included in this Offer. Adding Coke will be charged extra. Coca-Cola, Thumbs up, Coffee products contain caffeine. Coke Zero contains caffeine & non-caloric sweetener. This carbonated water contains an admixture of sucralose & acesulfame potassium. Not recommended for children; pregnant & lactating mothers.'
      },
      {
        id: 10,
        content:
          'This Offer is applicable only in Burger King Dine-in/ Takeaway.'
      }
    ];

    return (
      <div
        className={`privacy-pg_wrapper ${
          pathname === '/promotional-terms' ? 'privacy-pg_full' : ''
        }`}
      >
        {pathname === '/promotional-terms' ? (
          ''
        ) : (
          <AccountSubSecTitle
            title="Promotional Terms and Conditions"
            goBackOnClick={goBackOnClick}
            hideBackArrow={pathname === '/promotional-terms' || false}
            theme={theme}
          />
        )}
        <div className="privacy-pg">
          {pathname === '/promotional-terms' ? (
            <AccountSubSecTitle
              title="Promotional Terms and Conditions"
              goBackOnClick={goBackOnClick}
              hideBackArrow={pathname === '/promotional-terms' || false}
              theme={theme}
            />
          ) : (
            ''
          )}
          <div className="privacy-pg__content">
            <p className="privacy-pg__title">Terms and Conditions (T&C)</p>
            <p className="privacy-pg__title  u_text_transform_uppercase">
              IMPORTANT : PLEASE CAREFULLY READ AND UNDERSTAND THESE PROMOTIONAL
              TERMS AND CONDITIONS. THEY CONTAIN LIMITATIONS ON RESTAURANT
              BRANDS ASIA LIMITED’S LIABILITY AND OTHER PROVISIONS THAT AFFECT
              YOUR LEGAL RIGHTS.
            </p>
            <ol className="privacy-pg__list">
              {terms_conditions.map((term, index) => (
                <li
                  className={`privacy-pg__info ${
                    index === 3 ? 'bold-text' : ''
                  }`}
                  key={term.id}
                >
                  {term.content}
                  {term.subItems && (
                    <ul
                      style={{
                        listStyleType: 'disc'
                      }}
                    >
                      {term.subItems.map((subItem, index) => (
                        <>
                          <br />
                          <li key={index} className="ml-2 bold-text">
                            {subItem}
                          </li>
                        </>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ol>
            {/* </div> */}

            <br />
            <p className="privacy-pg__title  u_text_transform_uppercase">
              Burger King Express
            </p>
            <br />
            <ol className="privacy-pg__list">
              {bkExpress.map(term => (
                <li className="privacy-pg__info" key={term.id}>
                  {term.content}
                  {term.subItems && (
                    <ul
                      style={{
                        listStyleType: 'disc'
                      }}
                    >
                      {term.subItems.map((subItem, index) => (
                        <>
                          <br />
                          <li key={index} className="ml-2">
                            {subItem}
                          </li>
                        </>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ol>

            <br />
            <p className="privacy-pg__title  u_text_transform_uppercase">
              Terms & Condition of offer 2 Crispy Veg for ₹79* and 2 Crispy
              Chicken for ₹99*
            </p>
            <br />
            <ol className="privacy-pg__list">
              {ccTerms.map(term => (
                <li className="privacy-pg__info" key={term.id}>
                  {term.content}
                  {term.subItems && (
                    <ul
                      style={{
                        listStyleType: 'disc'
                      }}
                    >
                      {term.subItems.map((subItem, index) => (
                        <>
                          <br />
                          <li key={index} className="ml-2">
                            {subItem}
                          </li>
                        </>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ol>

            <br />
            <p className="privacy-pg__title  u_text_transform_uppercase">
              Terms & Condition of offer Tasty Meals starting @Rs. 99*
            </p>
            <br />
            <ol className="privacy-pg__list">
              {vegTermsData.map(term => (
                <li className="privacy-pg__info" key={term.id}>
                  {term.content}
                  {term.subItems && (
                    <ul
                      style={{
                        listStyleType: 'disc'
                      }}
                    >
                      {term.subItems.map((subItem, index) => (
                        <>
                          <br />
                          <li key={index} className="ml-2">
                            {subItem}
                          </li>
                        </>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ol>
          </div>
        </div>
        {pathname === '/promotional-terms' ? <Footer /> : ''}
      </div>
    );
  }
}

export default withRouter(PromotionalPrivacyPolicy);
