import React from 'react';
import './index.scss';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import {
  LocalStorage,
  SessionStorage
} from '../../burger-king-lib/utils/storage.web';
import Restaurant from '../../../assets/images/marker-brown1.png';
import Delivery from '../../../assets/images/marker.png';
import LOGO from '../../../assets/images/logo.png';
import { getOutlet } from '../../burger-king-lib/redux/address/actions';
import { connect } from 'react-redux';

class GoogleMapSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      markers: [],
      currentLocationMarker: null,
      latlng: LocalStorage.get(LocalSessionKey.LAT_LNG) || { lat: 0, lng: 0 },
      zoomLevel: LocalStorage.get('mapZoomLevel') || 18,
      theme: SessionStorage.get(LocalSessionKey.ORDER_TYPE)
    };
  }
  componentDidMount() {
    if (!window.google) {
      console.error('Google Maps API not loaded');
      return;
    }
    this.initializeMap();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.outletList !== this.props.outletList) {
      console.log('Outlet list updated', this.props.outletList);

      this.updateMarkers(this.props.outletList);
    }
    // Check for storeHorizontalVal changes
    if (
      this.props.storeHorizontalVal &&
      (prevProps.storeHorizontalVal?.lat !==
        this.props.storeHorizontalVal?.lat ||
        prevProps.storeHorizontalVal?.lng !==
          this.props.storeHorizontalVal?.lng)
    ) {
      const isNearbyStores = SessionStorage.get(
        LocalSessionKey.NEARBY_BK_RESTUARANT
      );
      if (!isNearbyStores) {
        this.moveMarkerToStorePosition();
      }
    }
  }

  componentWillUnmount() {
    const { map, markers, currentLocationMarker } = this.state;

    // Clean up markers
    if (Array.isArray(markers)) {
      markers.forEach(marker => marker.setMap(null)); // Remove markers from the map
    }

    // Clean up the current location marker
    if (currentLocationMarker) {
      currentLocationMarker.setMap(null); // Remove the current location marker
    }

    // Clean up the map (if needed)
    if (map) {
      // Remove any event listeners attached to the map
      window.google.maps.event.clearInstanceListeners(map);
    }

    // Reset state (optional, but recommended)
    this.setState({ map: null, markers: [], currentLocationMarker: null });

    this.handleNearbyStores();
  }

  moveMarkerToStorePosition = () => {
    if (!this.props.storeHorizontalVal) return;

    const position = {
      lat: parseFloat(this.props.storeHorizontalVal.lat),
      lng: parseFloat(this.props.storeHorizontalVal.lng)
    };

    // First update the map center
    if (this.state.map) {
      this.state.map.setCenter(position);
      this.state.map.setZoom(18);
    }

    // Then update the marker
    this.clearCurrentLocationMarker();
    this.createNewMarker(position);
  };

  clearCurrentLocationMarker = () => {
    if (this.state.currentLocationMarker) {
      this.state.currentLocationMarker.setMap(null);
    }
  };

  createNewMarker = position => {
    const { google } = window;
    const { map, theme } = this.state;

    if (!google || !map || !position.lat || !position.lng) return;

    const image = theme === 'delivery' ? Delivery : Restaurant;

    const marker = new google.maps.Marker({
      position: position,
      map: map,
      icon: {
        url: image,
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(20, 40)
      },
      draggable: false
    });

    this.setState({ currentLocationMarker: marker });
  };

  handleStoreHorizontalUpdate = () => {
    const isNearbyStores = SessionStorage.get(
      LocalSessionKey.NEARBY_BK_RESTUARANT
    );

    if (!isNearbyStores && this.props.storeHorizontalVal) {
      const position = {
        lat: parseFloat(this.props.storeHorizontalVal.lat),
        lng: parseFloat(this.props.storeHorizontalVal.lng)
      };
      this.updateMapCenter(position);
      this.updateCurrentLocationMarker(position);
    }
  };

  setInitialPosition = () => {
    const isNearbyStores = SessionStorage.get(
      LocalSessionKey.NEARBY_BK_RESTUARANT
    );

    if (!isNearbyStores && this.props.storeHorizontalVal) {
      // Use storeHorizontalVal position
      const position = {
        lat: parseFloat(this.props.storeHorizontalVal.lat),
        lng: parseFloat(this.props.storeHorizontalVal.lng)
      };
      this.updateMapCenter(position);
      this.updateCurrentLocationMarker(position);
    } else if (isNearbyStores) {
      // Use current location
      this.getCurrentLocation();
    }
  };

  getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          this.updateMapCenter(userLocation);
          this.updateCurrentLocationMarker(userLocation);

          // Set appropriate zoom level for current location
          if (this.state.map) {
            this.state.map.setZoom(18);
          }
        },
        error => {
          console.error('Error getting location:', error);
        }
      );
    }
  };

  handleLocationSelect = location => {
    if (location && location.lat && location.lng) {
      const position = {
        lat: parseFloat(location.lat),
        lng: parseFloat(location.lng)
      };

      // Update map center
      this.updateMapCenter(position);

      // Update local storage
      LocalStorage.set(LocalSessionKey.LAT_LNG, position);

      // Update current location marker
      this.updateCurrentLocationMarker(position);

      // If setLatLng prop exists, call it with the new position
      if (this.props.setLatLng) {
        this.props.setLatLng(position);
      }
    }
  };

  initializeMap = () => {
    const { google } = window;
    if (!google) return;
    console.log('Initial latlng:', this.state.latlng);
    const map = new google.maps.Map(document.getElementById('map'), {
      center: this.state.latlng,
      zoom: 18,
      disableDefaultUI: false, // Enable default controls
      zoomControl: true, // Ensure zoom control is visible
      mapTypeControl: true, // Optional: Show map type switcher
      draggable: true,
      mapTypeId: 'roadmap'
    });
    this.setState({ map }, () => {
      console.log('Map initialized');
      // After map is initialized, check if we should show store position or current location
      const isNearbyStores = SessionStorage.get(
        LocalSessionKey.NEARBY_BK_RESTUARANT
      );
      if (!isNearbyStores && this.props.storeHorizontalVal) {
        this.moveMarkerToStorePosition();
      } else if (isNearbyStores) {
        this.handleNearbyStores();
      }
    });
  };

  updateMapCenter = location => {
    const { map } = this.state;
    if (!map || !location.lat || !location.lng) return;

    map.setCenter({
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lng)
    });
    this.updateCurrentLocationMarker({
      lat: parseFloat(location.lat),
      lng: parseFloat(location.lng)
    });
  };

  handleCurrentLocationClick = () => {
    const { google } = window;
    const { map, currentLocationMarker, theme } = this.state;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        // Remove existing marker
        if (currentLocationMarker) {
          currentLocationMarker.setMap(null);
        }

        // Create new marker
        const image = theme === 'delivery' ? Delivery : Restaurant;
        const marker = new google.maps.Marker({
          position: userLocation,
          map,
          icon: {
            url: image,
            scaledSize: new google.maps.Size(40, 40),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(20, 40)
          },
          draggable: false
        });

        // Update state with new marker
        this.setState({
          currentLocationMarker: marker,
          zoomLevel: 18 // Reset zoom level when changing location
        });

        // Center map and set zoom
        if (map) {
          map.setCenter(userLocation);
          map.setZoom(18);
        }
      });
    }
  };

  updateCurrentLocationMarker = position => {
    const { google } = window;
    const { map, currentLocationMarker, theme } = this.state;

    if (!map || !position.lat || !position.lng) return;

    // Remove previous marker
    if (currentLocationMarker) {
      currentLocationMarker.setMap(null);
    }

    const image = theme === 'delivery' ? Delivery : Restaurant;

    // Create new marker
    const marker = new google.maps.Marker({
      position,
      map,
      icon: {
        url: image,
        scaledSize: new google.maps.Size(40, 40),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(20, 40)
      },
      draggable: false
    });

    // Store marker in state
    this.setState({ currentLocationMarker: marker });
  };

  updateMarkers = outletList => {
    const { google } = window;
    const { map, markers } = this.state;

    if (!google || !map) return;

    // ✅ Ensure markers is an array before calling forEach
    if (Array.isArray(markers)) {
      markers.forEach(marker => marker.setMap(null));
    }

    // ✅ Use optional chaining for safety
    const newMarkers = outletList
      ?.filter(outlet => outlet.lat && outlet.long)
      .map(outlet => {
        const marker = new google.maps.Marker({
          position: {
            lat: parseFloat(outlet.lat),
            lng: parseFloat(outlet.long)
          },
          map,
          icon: this.getMarkerIcon(),
          draggable: false
        });

        marker.addListener('click', () => this.handleMarkerClick(outlet));
        return marker;
      });

    this.setState({ markers: newMarkers || [] }); // ✅ Ensure it's always an array
  };

  getMarkerIcon = () => {
    const { google } = window;

    return {
      url: LOGO,
      scaledSize: new google.maps.Size(40, 40),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(20, 40)
    };
  };

  setupMapListeners = map => {
    map.addListener('zoom_changed', () => {
      const newZoom = map.getZoom();
      this.setState({ zoomLevel: newZoom });
      LocalStorage.set('mapZoomLevel', newZoom);
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        this.updateCurrentLocationMarker(userLocation);
      });
    }
  };

  setupZoomControls = map => {
    const { google } = window;

    const zoomInButton = document.createElement('button');
    zoomInButton.textContent = '+';
    zoomInButton.classList.add('zoom-button', 'zoom-in');
    zoomInButton.addEventListener('click', () =>
      map.setZoom(map.getZoom() + 1)
    );

    const zoomOutButton = document.createElement('button');
    zoomOutButton.textContent = '-';
    zoomOutButton.classList.add('zoom-button', 'zoom-out');
    zoomOutButton.addEventListener('click', () =>
      map.setZoom(map.getZoom() - 1)
    );

    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomOutButton);
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomInButton);
  };

  handleNearbyStores = () => {
    console.log('nearby store is called');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          // Clear existing marker first
          this.clearCurrentLocationMarker();

          // Update map center
          if (this.state.map) {
            this.state.map.setCenter(userLocation);
            this.state.map.setZoom(18);
          }

          // Create new marker at current location
          this.createNewMarker(userLocation);

          // Prepare payload for API call
          const payload = {
            lat: position.coords.latitude,
            long: position.coords.longitude
          };

          // Set session storage and dispatch action
          SessionStorage.set(LocalSessionKey.NEARBY_BK_RESTUARANT, true);
          console.log('Nearby stores clicked');
          this.getCurrentLocation();
          this.props.getOutletData(payload);
        },
        error => {
          console.error('Error getting location:', error);
        }
      );
    }
  };

  render() {
    const textColor =
      SessionStorage.get(LocalSessionKey.ORDER_TYPE) === 'delivery'
        ? '#EE7000'
        : '#6F3C2F';

    return (
      <div className="google-map-container">
        <div id="map" className="map"></div>
        <button
          className="nearby-stores-button"
          onClick={this.handleNearbyStores}
          style={{ color: textColor }}
        >
          BK Restaurants Near You
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  outletList: state?.address?.outlets?.content
});

const mapDispatchToProps = dispatch => ({
  getOutletData: payload => dispatch(getOutlet(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMapSearch);
